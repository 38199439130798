const React = require('react');
const PropTypes = require('prop-types');

const ChevronRight16 = require('@andes/icons/ChevronRight16');
const ArrowRight = require('@andes/icons/ArrowRight16');

const { THEME } = require('./utils');

const IconContainer = ({ theme }) => {
  let Icon = null;

  if (theme === THEME.LEGEND) {
    Icon = <ChevronRight16 />;
  } else if (theme === THEME.EXPLORER) {
    Icon = <ArrowRight />;
  } else {
    Icon = <></>;
  }

  return Icon;
};

IconContainer.propTypes = {
  theme: PropTypes.string,
};

IconContainer.defaultProps = {
  theme: '',
};

module.exports = IconContainer;
